import * as React from 'react'
import { Link } from 'gatsby'

import styled from '@emotion/styled'
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import CtaLink from '../components/CtaLink'

const StyledShopTeaser = styled.div`
  padding: 20px;
  padding-top: 0;
  border: 1px solid #355724;
`

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <Container>
        {/*
        <h2>Aktuelle Informationen</h2>
        <h2>Aktuelle Informationen aufgrund vom Coronavirus</h2>
        */}

        {/*
        <StyledShopTeaser>
          <br />
          <p>Laut aktuellem Stand können Sie uns auf allen Märkten zu den gewohnten Öffnungszeiten besuchen.</p>
          <p>
          <strong>Der Markt in Hallein (Fr 14-17 Uhr) findet trotz aktueller Ausfahrtsbeschränkungen wie gewohnt am Kornsteinplatz statt.</strong>
          </p>
          <p>Der Markt am Kajetanerplatz (Fr 8-13 Uhr) findet ab 2.4.2021 wegen einer Baustelle im Unipark Nonntal statt.</p>
        </StyledShopTeaser>
          */}

        {/*
        <StyledShopTeaser>
          <h2>Betriebsurlaub</h2>
          <p>In der Woche KW 33 (15.-17. August 2024) sind wir auf den Märkten nicht vertreten.</p>
          <p>Wir wüschen allen einen schönen Sommer!</p>
        </StyledShopTeaser>
        */}

        {/*
        <StyledShopTeaser>
          <h2>Betriebsurlaub</h2>
          <p>letzer Markt findet am 23. Dezember 2023 statt, ab Donnerstag, 11. Jänner 2024, sind wir auf den Märkten wieder für Sie da.</p>
          <p>Selbstbedienung im Hofladen ist - mit reduziertem Angebot - ausgenommen Sonn-/Feiertag geöffnet.</p>
          <p>Wir wüschen allen frohe Weihnachten und einen guten Rutsch ins neue Jahr!</p>
        </StyledShopTeaser>
          */}

        <br />
        <p>
          Kontakt: <a href="mailto:sams@samshofbauer.at">sams@samshofbauer.at</a> oder telefonisch <a href="tel:+4362165397">06216 5397</a>
        </p>
        <StyledShopTeaser>
          <h2>Online Shop</h2>
          <ul>
            <li>Abholung im Hofladen (nächsten Tag ab 9:00)</li>
            <li>Abholung auf den Märkten</li>
            {/*
            <li>Zustellung im Raum Neumarkt (Termin flexibel)</li>
            <li>Zustellung im Raum Salzburg (Termin: Donnerstag Vormittag)</li>
            */}
          </ul>
          <CtaLink to="/shop">»» zum Shop</CtaLink>
        </StyledShopTeaser>

        <br />
        <h2>Öffnungszeiten Märkte:</h2>
        <ul>
          <li>
            Donnerstag: <strong>Schranne</strong>, Salzburg: 06:00 - 13:00
          </li>
          <li>
            Donnerstag: <strong>Henndorf</strong>: 15:30 - 18:00
          </li>
          <li>
            Freitag: Bio-Markt Salzburg <strong>Kajetanerplatz</strong>: 08:00 - 13:00
          </li>
          <li>
            Freitag: Bio-Markt <strong>Hallein</strong> Kornsteinplatz: 14:00 - 17:00
          </li>
          <li>
            Samstag: <strong>Grünmarkt</strong> Salzburg, Max-Reinhardt-Platz: 08:00 - 12:30
          </li>
        </ul>

        <h2>Öffnungszeiten Hofladen:</h2>
        <ul>
          <li>
            Freitag: Hofladen in <strong>Neumarkt</strong>: 9:00 - 12:00 und 14:00 - 18:00
          </li>
          <li>
            Mo-Sa: Selbstbedienung im Hofladen <strong>Neumarkt</strong>: 07:00 - 19:00
          </li>
        </ul>

        <h2>So finden Sie zu uns</h2>
        <iframe
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJh-ZuKNWfdkcRq_Rz55yA_e4&key=AIzaSyBg3p0lN-NYeq7uLfE5W6KgzB0qGFHYaX0"
          allowFullScreen
        />
      </Container>
    </Page>
  </IndexLayout>
)

export default IndexPage
