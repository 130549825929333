import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const CtaLink = styled(Link)`
  background-color: #355724;
  padding: 10px;
  color: white;
  &:hover {
    text-decoration: none;
  }
`
export default CtaLink
